<template>
    <a-drawer
        :title="$t('meeting.drawerTitle')"
        :visible="visible"
        class="meeting_edit_drawer"
        :class="isMobile && 'mobile'"
        @close="visible = false"
        destroyOnClose
        :afterVisibleChange="afterVisibleChange"
        :zIndex="zIndex"
        :width="drawerWidth"
        placement="right">
        <div class="drawer_body">
            <a-form-model
                ref="meetingForm"
                class="meeting_form"
                :model="form"
                :rules="rules">
                <a-form-model-item
                    ref="name"
                    :label="$t('meeting.conferenceName')"
                    prop="name">
                    <a-input
                        :max-length="255"
                        v-model="form.name"
                        @pressEnter="formSubmit()"
                        size="large" />
                </a-form-model-item>
                <a-form-model-item
                    :label="$t('meeting.description')"
                    prop="description">
                    <a-textarea
                        v-model="form.description"
                        size="large"
                        :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
                <a-form-model-item
                    :label="$t('meeting.startDateTime')"
                    prop="date_begin">
                    <DatePicker
                        v-model="form.date_begin"
                        size="large"
                        :show-time="{ format: 'HH:mm' }"
                        @pressEnter="formSubmit()" />
                </a-form-model-item>
                <a-form-model-item
                    ref="duration"
                    :label="$t('meeting.duration')"
                    prop="duration">
                    <a-input-number
                        v-model="form.duration"
                        :min="0"
                        @pressEnter="formSubmit()"
                        :max="1000" />
                </a-form-model-item>
                <a-form-model-item
                    ref="members"
                    :label="$t('meeting.participants')"
                    prop="members">
                    <!-- <a-button 
                        type="ui" 
                        icon="fi-rr-user-add"
                        flaticon
                        :block="isMobile"
                        @click="userDrawer = true">
                        {{ $t('meeting.addParticipant') }}
                    </a-button> -->
                    <!-- <UserDrawer
                        v-model="form.members"
                        :deleted="deleted"
                        :visible="userDrawer"
                        :drawerClose="closeUserDrawer" /> -->
                    <UserDrawer 
                        id="meetingCreate"
                        multiple
                        :buttonText="$t(edit ? 'meeting.change_participant_list' : 'meeting.add_participants')"
                        :changeMetadata="changeMetadata"
                        :metadata="{ key: 'members', value: form.metadata }"
                        v-model="form.members"
                        @change="changeUsers" />
                    <template v-if="form.members && form.members.length" >
                        <div class="meeting_users">
                            <div class="label font-light">
                                {{ $t('meeting.added') }} {{ memberCount }}
                            </div>
                            <UserDrawerCard
                                v-for="user in meetingMembers"
                                :key="user.id"
                                :memberDelete="memberDelete"
                                :memberType="memberType"
                                :user="user" />
                        </div>
                    </template>
                </a-form-model-item>
            </a-form-model>

        </div>
        <div class="drawer_footer">
            <a-button 
                :loading="loading" 
                :block="isMobile"
                :size="isMobile ? 'large' : 'default'"
                @click="formSubmit()"
                type="primary">
                {{ emded ? $t('meeting.save') : $t('meeting.create') }}
            </a-button>
        </div>
    </a-drawer>
</template>

<script>
import UserDrawer from '@apps/DrawerSelect/index.vue'
import UserDrawerCard from './UserDrawerCard.vue'
import eventBus from '../utils/eventBus'
import gEventBus from '@/utils/eventBus'
import { declOfNum } from '../utils'
import DatePicker from '@apps/Datepicker'
import { mapActions } from 'vuex'
import Vue from 'vue'
export default {
    name: "MeetingCreateDrawer",
    props: {
        emded: {
            type: Boolean,
            default: false
        },
        parentVisible: {
            type: Boolean,
            default: false
        },
        closeDrawer: {
            type: Function,
            default: () => {}
        },
        meeting: {
            type: Object,
            default: () => null
        },
        updateMeeting: {
            type: Function,
            default: () => {}
        },
        zIndex: {
            type: Number,
            default: 1010
        },
        pageName: {
            type: String,
            default: 'page_list_meetings.PlannedMeetingModel'
        }
    },
    components: {
        UserDrawer,
        UserDrawerCard,
        DatePicker
    },
    computed: {
        drawerTitle() {
            return this.edit ? this.$t('meeting.editConference') : this.$t('meeting.createConference')
        },
        model() {
            return this.$store.state.meeting.showEdit.model
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
        drawerWidth() {
            return this.windowWidth > 500 ? 500 : '100%'
        },
        meetingMembers() {
            return this.form.members
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        visible: {
            get() {
                if(this.emded)
                    return this.parentVisible
                else
                    return this.$store.state.meeting.showEdit.show
            },
            set(val) {
                if(!val)
                    this.edit = false

                if(this.emded)
                    this.closeDrawer()
                else {
                    this.$store.commit('meeting/SET_EDIT_DRAWER', { show: val, model: 'main' })
                }
            }
        },
        memberCount() {
            return this.form.members.length + ' ' + declOfNum(this.form.members.length,
                [this.$t('meeting.participant'), this.$t('meeting.participantGen'), this.$t('meeting.participantsGen')])
        }
    },
    data() {
        return {
            userDrawer: false,
            edit: false,
            deleted: [],
            initMembers: [],
            previousMembers: [],
            memberCountKey: 1, 
            form: {
                metadata: { members: [] },
                name: '',
                date_begin: this.$moment(),
                duration: 50,
                members: [],
                model: ''
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: this.$t('meeting.requiredField'),
                        trigger: 'blur',
                        whitespace: true,
                    }
                ],
                date_begin: [
                    {
                        required: true,
                        message: this.$t('meeting.requiredField'),
                        trigger: 'blur'
                    }
                ],
                duration: [
                    {
                        min: 0,
                        max: 1000,
                        type: "number",
                        trigger: 'blur',
                        whitespace: true,
                    }
                ]
            },
            loading: false
        }
    },
    watch: {
        async visible(val) {
            if(val) {
                if(this.meeting) {
                    this.edit = true
                    const res = await this.getUsersMeeting(this.meeting.id)
                    const formData = JSON.parse(JSON.stringify(this.meeting))
                    this.form = {
                        ...formData,
                        date_begin: this.$moment(formData.date_begin)
                    }
                    Vue.set(this.form, 'members', [])
                    this.initMembers.push(...res.results.map(el=> ({
                        ...el.user,
                        is_moderator: el.is_moderator,
                        updated: false,
                        added: false
                    }) 
                    ))
                    this.form.members.push(...res.results.map(el=> ({
                        ...el.user,
                        is_moderator: el.is_moderator,
                        updated: false,
                        added: false
                    }) 
                    ))
                    this.form.metadata.members[0].users = res.results.map(us => us.user)
                    this.form.metadata.members[0].userCount = res.count
                }
                
            }
        }
    },
    created() {
        setTimeout(() => {
            this.checkOpen()
        }, 600)
    },
    methods: {
        ...mapActions({
            getUsersMeeting: 'meeting/getUsersMeeting'
        }),
        changeUsers(users) {

            const oldMembersMap = new Map(this.initMembers.map(m => [m.id, m]))
            const newMembersMap = new Map(users.map(m => [m.id, m]))

            users.forEach(user => {
                if (!oldMembersMap.has(user.id)) {
                    this.$set(user, "added", true)
                } else {
                    const original = oldMembersMap.get(user.id)
                    const { added, updated, ...newData } = user
                    const { added: oldAdded, updated: oldUpdated, ...oldData } = original
                    if (JSON.stringify(newData) !== JSON.stringify(oldData)) {
                        this.$set(user, "updated", true)
                    }
                }
            })

            const deletedUsers = this.initMembers.filter(member => !newMembersMap.has(member.id))
            deletedUsers.forEach(user => {
                if (!this.deleted.some(deletedUser => deletedUser.id === user.id)) {
                    this.deleted.push(user)
                }
            })

            this.initMembers = JSON.parse(JSON.stringify(users))
            this.form.members = users
        },
        changeMetadata({key, value}) {
            Vue.set(this.form.metadata, key, value)
        },

        afterVisibleChange(vis) {
            if(!vis) {
                this.clearForm()
            }
        },
        checkOpen() {
            if(this.$route.query?.createMeetings) {
                this.visible = true
                let query = Object.assign({}, this.$route.query)
                delete query.createMeetings
                this.$router.push({query})
            }
        },
        clearForm() {
            this.deleted = []
            this.previousMembers = []
            this.form = {
                metadata: { members: [] },
                name: '',
                date_begin: this.$moment(),
                duration: 50,
                members: [],
                memberKey: 1,
                model: ''
            }
        },
        formSubmit() {
            this.$refs.meetingForm.validate(async valid => {
                if (valid) {
                    
                    let queryData = {
                        ...this.form
                    }
                    if(queryData.members?.length) {
                 
                        queryData.members = queryData.members.map(user => {
                            return {
                                user: user.id,
                                added: user.added,
                                updated: user.updated,
                                is_moderator: user.is_moderator
                            }
                        })
                        let data = queryData.members
                        if(this.edit){                             
                            queryData.members = {add: [], update: [], delete: []}
                         
                            queryData.members.add =  data.filter(el=> el.added)
                            queryData.members.delete = this.deleted.filter(el=>!el.added).map(el=>el.id)
                            queryData.members.edit =  data.filter(el=> el.updated)
                          
                        }
                    }

                    if(!this.edit) {
                        try {
                            this.loading = true
                            const { data } = await this.$http.post('/meetings/create/', queryData)
                            this.$message.success(this.$t('meeting.conferenceCreated'))
                            this.visible = false
                            this.clearForm()
                            gEventBus.$emit(`table_row_${this.pageName}`, { 
                                action: 'create',
                                row: data
                            })
                            gEventBus.$emit('reload_meetings_list', true)
                            eventBus.$emit('reload_meetings_list', true)
                        } catch(e) {
                            this.$message.error(this.$t('meeting.error'))
                            console.log(e)
                        } finally {
                            this.loading = false
                        }
                    } else {
                        try {
                            this.loading = true

                            const {data} = await this.$http.put(`/meetings/${this.meeting.id}/update/`, queryData)
                            this.$message.success(this.$t('meeting.conferenceUpdated'))
                            this.visible = false
                            this.clearForm()
                            this.updateMeeting({meeting: data})
                            gEventBus.$emit(`table_row_${this.pageName}`, { 
                                action: 'update',
                                row: data
                            })
                            gEventBus.$emit('reload_meetings_list', true)
                            eventBus.$emit('reload_meetings_list', true)
                        } catch(e) {
                            this.$message.error(this.$t('meeting.error'))
                            console.log(e)
                        } finally {
                            this.loading = false
                        }
                    }
                    
                } else
                    return false
            })
        },
        closeUserDrawer() {
            this.userDrawer = false
        },
        memberDelete(user) {
            const index = this.form.members.findIndex(f => f.id === user.id)
            if(index !== -1)
                this.form.members.splice(index, 1)
            const indexMeta = this.form.metadata.members[0].users.findIndex(f => f.id === user.id)
            if(indexMeta !== -1)
                this.form.metadata.members[0].users.splice(indexMeta, 1)
            this.deleted.push(user)    
        },
        memberType(user) {
            const index = this.form.members.findIndex(f => f.id === user.id)
            if(index !== -1) { 
                this.form.members[index].is_moderator = !this.form.members[index].is_moderator
                this.form.members[index].updated = true
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.meeting_edit_drawer{
    .meeting_users{
        .user_card_drawer{
            &:not(:last-child) {
                border-bottom: 1px solid #e8e8e8;
            }
        }
    }
    &::v-deep{
        .ant-drawer-wrapper-body,
        .ant-drawer-content{
            overflow: hidden;
        }
        .ant-drawer-header{
            padding-left: 20px;
            padding-right: 20px;
        }
        .ant-drawer-body{
            height: calc(100% - 40px);
            padding: 0px;
            .drawer_body{
                height: calc(100% - 40px);
                overflow-y: auto;
                .meeting_form{
                    padding: 20px;
                }
            }
            .drawer_footer{
                display: flex;
                align-items: center;
                height: 40px;
                border-top: 1px solid #e8e8e8;
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
    &.mobile{
        .drawer_body{
            height: calc(100% - 48px);
            .meeting_form{
                padding: 20px 15px;
            }
        }
        .drawer_footer{
            height: 48px;
            padding-left: 15px;
            padding-right: 15px;
        }
        &::v-deep{
            .ant-drawer-header{
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}
</style>